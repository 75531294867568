import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export type UseSearchParamsHandleSetSearchParams = (
  nextSearchParamValues: Record<string, string>
) => void

export const useSearchParams = () => {
  const history = useHistory()
  const { search } = useLocation()

  const handleSetSearchParams = useCallback<UseSearchParamsHandleSetSearchParams>(
    (nextSearchParamValues) => {
      const nextSearchParams = new URLSearchParams(nextSearchParamValues)

      history.replace({ search: nextSearchParams.toString() })
    },
    [history]
  )

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  return useMemo(() => [searchParams, handleSetSearchParams] as const, [
    handleSetSearchParams,
    searchParams,
  ])
}
