import { Box, Typography } from '@mui/material'
import React from 'react'

import { AuthenticationButton } from '../../authentication/components/AuthenticationButton/AuthenticationButton'
import { SignUp } from '../components/SignUp/Signup'

const EmailSent: React.FC = () => {
  return (
    <>
      <SignUp />
      <Box sx={{ width: '400px', margin: '0 auto', marginTop: '16vh' }}>
        <Typography variant="h2" sx={{ marginBottom: '50px' }}>
          Password reset email sent
        </Typography>
        <Typography variant="body1">
          If an account linked to that email address exists, you will receive instructions to reset
          your password. The email may take a few minutes to arrive.
          <br />
          <br />
          Haven't received the instructions?
          <br />
          <a href="/password/new">Resend email or change email address</a>
          <br />
          <a href="mailto:support@coderpad.io?subject=Password help">Contact support</a> if you
          continue to have problems.
        </Typography>
        <AuthenticationButton href="/login" sx={{ width: 'fit-content', marginTop: '24px' }}>
          Return to login page
        </AuthenticationButton>
      </Box>
    </>
  )
}

export default EmailSent
