// POLYFILLS
// The babel-preset-env setting {useBuiltIns: 'entry'} makes this
// import all the polyfills needed for an environment, and only those.
// https://babeljs.io/docs/en/babel-preset-env
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#usebuiltins-entry-with-corejs-3
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// This import will set up analytics/logging keys and the window.CoderPad object.
import '../utils/initialize'

import { ApolloProvider } from '@apollo/client'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom'

import { useTheme } from '../theme/theme'
import { buildApolloClient } from '../utils/buildApolloClient/buildApolloClient'
import { createFetcher } from '../utils/fetch/fetch'
import { FetchProvider } from '../utils/fetch/FetchProvider'
import { ErrorBoundary } from './dashboard/components/ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from './dashboard/components/GenericErrorView/GenericErrorView'
import { Login } from './login/index'
const token =
  document.querySelector("[name='csrf-token']")?.attributes.getNamedItem('content')?.value || ''
const fetcher = createFetcher(token)

const client = buildApolloClient()

const LoginContent = () => {
  const theme = useTheme()

  return (
    <ErrorBoundary fallback={(e) => <GenericErrorView error={e} />}>
      <FetchProvider fetcher={fetcher}>
        <ApolloProvider client={client}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Login />
            </ThemeProvider>
          </StyledEngineProvider>
        </ApolloProvider>
      </FetchProvider>
    </ErrorBoundary>
  )
}

ReactDOM.render(<LoginContent />, document.getElementById('login-root'))
