import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles<Theme>((theme) => ({
  instructions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    marginTop: '64px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
    '& > a': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '18px',
      color: '#2D72CC',
    },
  },
}))
