import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  coderpad: {
    width: '150px',
    height: '21px',
  },
  hasAccount: {
    fontFamily: 'Open Sans',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: '40px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingTop: '20px',
    },
  },
}))
