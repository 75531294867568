import { Amplify, Auth, Hub } from 'aws-amplify'
import { LoadingBlock } from 'packs/dashboard/components/LoadingBlock/LoadingBlock'
import React, { useEffect, useRef, useState } from 'react'
import {
  AmplifyCustomState,
  getStoredAmplifyConfiguration,
  getStoredAmplifyCustomState,
  removeStoredAmplifyConfiguration,
  removeStoredCustomState,
} from 'utils/amplify/setAmplifyConfig'

import { useSearchParams } from '../../../utils'
import { useFetchNewLoginSession } from '../useLogin/useLogin'

const INVALID_SAML_HEADER = 'Invalid SAML response received: '
const PRESIGNUP_ERROR_HEADER = `${INVALID_SAML_HEADER}PreSignUp failed with error `

export const COGNITO_SSO_ERROR_KEY = 'COGNITO_SSO_ERROR'

interface ErrorDetails {
  error?: string
  mail?: string
}

export enum SsoSigninErrorType {
  CANNOT_VALIDATE_CONFIG = 'CANNOT_VALIDATE_CONFIG',

  // Errors from the PreLambda Signup
  CANNOT_LOGIN = 'CANNOT_LOGIN',
  UNKNOWN_EMAIL = 'UNKNOWN_EMAIL',
  MULTIPLE_USERS_WITH_EMAIL = 'MULTIPLE_USERS_WITH_EMAIL',
  IDP_LINK_SUCCESSFUL = 'IDP_LINK_SUCCESSFUL',
  UNKNOWN = 'UNKNOWN',
}

// Try to parse the error description, it might contain some unrelevant headers
// Errors we throw in the preSignup Lambda are of the form "ERROR_CODE;EMAIL", try to get this information if the error has this format too
function parseErrorDescription(error?: string | null): ErrorDetails {
  if (!error) {
    return {
      error: undefined,
    }
  }
  let parsedError: string
  if (error.startsWith(PRESIGNUP_ERROR_HEADER)) {
    // Expected error if cognito-sso is correctly set-up but the user might have tried to log-in as a wrong user,
    // Or it's real user is not correctly setup in cognito
    // Or other unexpected errors from the preSignup Lambda, maybe the error-format has changed ?
    parsedError = error.slice(PRESIGNUP_ERROR_HEADER.length)
  } else if (error.startsWith(INVALID_SAML_HEADER)) {
    // Expected error if there's other unexpected problems from cognito, wrong set-up ?
    parsedError = error.slice(INVALID_SAML_HEADER.length)
  } else {
    // Likely a small chenapan that plays with the url parameters ?
    parsedError = error
  }

  const knownError = Object.keys(SsoSigninErrorType).find((errorType) =>
    parsedError.startsWith(errorType)
  )

  if (knownError) {
    const [_, errorType, mail] = parsedError.match(/^(.*?);(.*?)[.\s]+$/) ?? []
    return {
      error: errorType,
      mail,
    }
  } else {
    return {
      error: parsedError,
    }
  }
}

const redirect = async (
  redirectUrl?: string | null,
  errorDescription?: string,
  attemptedEmail?: string
) => {
  if (redirectUrl == null && errorDescription != null) {
    localStorage.setItem(
      COGNITO_SSO_ERROR_KEY,
      JSON.stringify({ errorDescription, attemptedEmail })
    )
  }

  location.href = new URL(
    redirectUrl ?? (errorDescription != null ? '/login' : '/dashboard'),
    document.baseURI
  ).toString()
}

function PostSsoLoginPage() {
  const isFirstRender = useRef(true)

  const [searchParams] = useSearchParams()

  const [amplifySignInData, setAmplifySignInData] = useState<any>()
  const [amplifyCustomState, setAmplifyCustomState] = useState<AmplifyCustomState | null>()

  const fetchForCognitoSignIn = useFetchNewLoginSession()

  useEffect(() => {
    if (!isFirstRender.current) {
      return
    }

    isFirstRender.current = false

    const code = searchParams.get('code')
    const state = searchParams.get('state')
    const redirectUrl = searchParams.get('redirectUrl')
    const errorDescription = searchParams.get('error_description')

    const storedAmplifyConfig = getStoredAmplifyConfiguration()
    const storedAmplifyCustomState = getStoredAmplifyCustomState()

    if (code != null && state != null && storedAmplifyConfig != null && errorDescription == null) {
      // New SSO login workflow: login with a third party on cognito
      // Cognito redirects us here with a code & a sate, we still need to loginCognitoWithToken to be actually logged in
      Amplify.register(Auth)
      Amplify.configure(storedAmplifyConfig)

      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'signIn':
            setAmplifySignInData(data)
            break
          case 'customOAuthState': {
            // Check that the nonce we've set in the sessionStorage is the same we received in the Amplify customState
            if (data === storedAmplifyCustomState?.nonce) {
              setAmplifyCustomState(storedAmplifyCustomState)
            } else {
              redirect(
                null,
                SsoSigninErrorType.CANNOT_VALIDATE_CONFIG,
                storedAmplifyCustomState?.email
              )
            }
            break
          }
        }
      })
    } else {
      // Legacy workflow for not-on-cognito SSO users:
      // When we arrive here we have been logged in by the backend, we can directly redirect to the login page
      // Also handle possible SAML errors sent by Cognito
      const { error, mail } = parseErrorDescription(errorDescription)
      redirect(redirectUrl, error, mail ?? storedAmplifyCustomState?.email)
    }
  }, [searchParams])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      if (amplifySignInData != null && amplifyCustomState != null) {
        removeStoredAmplifyConfiguration()
        removeStoredCustomState()
        try {
          const { urlRedirect } = await fetchForCognitoSignIn(
            amplifySignInData.signInUserSession.idToken.getJwtToken(),
            amplifyCustomState.hydraLoginChallenge,
            false,
            true
          )
          redirect(urlRedirect)
        } catch (e) {
          console.error(e)
          redirect(null, SsoSigninErrorType.UNKNOWN, amplifyCustomState.email)
        }
      }
    })()
  }, [amplifySignInData, amplifyCustomState, fetchForCognitoSignIn])

  return <LoadingBlock isLoading />
}

export default PostSsoLoginPage
