import { useContext } from 'react'

import { fetcherContext } from './FetchProvider'

interface IFetcher {
  (url: string, opts: unknown): Promise<Response>
  authToken: string
}

/**
 * Convenience hook to grab the fetch function from the FetchProvider context.
 */
export function useFetch() {
  const fetcher = useContext(fetcherContext)

  return fetcher as IFetcher
}
