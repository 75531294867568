import { Amplify } from 'aws-amplify'
import { AuthFlow, CognitoUserPool } from 'graphql/types'

interface OAuth {
  domain: string
  redirectSignIn: string
  redirectSignOut?: string
  responseType: 'code'
}

export interface AmplifyConfiguration {
  Auth?: {
    region: string
    userPoolId?: string
    userPoolWebClientId?: string
    authenticationFlowType?: AuthFlow
    storage?: MemoryStorage
    oauth: OAuth
  }
}

export interface AmplifyCustomState {
  hydraLoginChallenge: string | null
  redirectUrl: string
  email: string
  migrated: boolean
  loginMode?: 'lever'
  nonce: string
}

const AMPLIFY_CONFIGURATION_KEY = 'amplifyConfiguration'
const AMPLIFY_CUSTOM_STATE_KEY = 'amplifyCustomState'

export function getStoredAmplifyConfiguration(): AmplifyConfiguration | null {
  try {
    return JSON.parse(window.sessionStorage.getItem(AMPLIFY_CONFIGURATION_KEY) ?? 'null')
  } catch (e) {
    return null
  }
}

export function storeAmplifyConfiguration(amplifyConfiguration: AmplifyConfiguration) {
  window.sessionStorage.setItem(AMPLIFY_CONFIGURATION_KEY, JSON.stringify(amplifyConfiguration)) // note: JSON.stringify won't serialize storage as it's a class
}

export function removeStoredAmplifyConfiguration() {
  window.sessionStorage.removeItem(AMPLIFY_CONFIGURATION_KEY)
}

export function getStoredAmplifyCustomState(): AmplifyCustomState | null {
  try {
    return JSON.parse(window.sessionStorage.getItem(AMPLIFY_CUSTOM_STATE_KEY) ?? 'null')
  } catch (e) {
    return null
  }
}

export function storeAmplifyCustomState(amplifyCustomState: AmplifyCustomState) {
  window.sessionStorage.setItem(AMPLIFY_CUSTOM_STATE_KEY, JSON.stringify(amplifyCustomState))
}

export function removeStoredCustomState() {
  window.sessionStorage.removeItem(AMPLIFY_CONFIGURATION_KEY)
}

/**
  Get current Amplify configuration for a given user depending on US/EU region
**/

let storage = {}
class MemoryStorage {
  static setItem(key: string, value: string) {
    storage[key] = value
    return storage
  }

  static getItem(key: string) {
    return storage[key]
  }

  static removeItem(key: string) {
    return delete storage[key]
  }

  static clear() {
    storage = {}
    return storage
  }
}

// UserPools can have custom domains, & are configured as such in CG for Cognito SSO signups
// We should use the custom domains for the userpools that have been configured
const userPoolCustomDomains = {
  'us-east-1_y3aJWInG6': 'cognito.coderpad.io',
  'us-east-1_sY00snYNg': 'cognito.coderpad-staging.io',
}

function getUserPoolDomain(userPool: CognitoUserPool): string {
  if (userPoolCustomDomains[userPool.userPoolId] != null) {
    return userPoolCustomDomains[userPool.userPoolId]
  }
  return `${userPool.name}.auth.${userPool.region}.amazoncognito.com`
}

export const buildAmplifyConfig = (
  userPool?: CognitoUserPool | null,
  authFlowType?: AuthFlow
): AmplifyConfiguration => {
  return userPool != null
    ? {
        Auth: {
          // REQUIRED - Amazon Cognito Region
          region: userPool.region,

          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: userPool.userPoolId,

          // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
          userPoolWebClientId: userPool.userPoolWebClientId,

          // OPTIONAL - Manually set the authentication flow type. Should help us with migrating users to cognito
          authenticationFlowType: authFlowType,

          // By using a storage class we prevent CognitoIdentityServiceProvider from storing info. in localStorage
          storage: MemoryStorage,

          oauth: {
            domain: getUserPoolDomain(userPool),
            redirectSignIn: new URL('/post_sso', location.origin).toString(),
            responseType: 'code',
          },
        },
      }
    : { Auth: undefined }
}

export const setAmplifyConfig = (userPool?: CognitoUserPool | null, authFlowType?: AuthFlow) => {
  Amplify.configure(buildAmplifyConfig(userPool, authFlowType))
}
