import jwtDecode from 'jwt-decode'
import React, { useCallback } from 'react'

import { track } from '../../../main/coderpad_analytics'
import GoogleLoginButton from './GoogleLoginButton'
import { useStyles } from './index.style'

interface GoogleSignInBtnProps {
  onLogin: (email: string, credential: string) => void
}

export const GoogleSignInBtn = ({ onLogin }: GoogleSignInBtnProps) => {
  const styles = useStyles()

  const handleClick = useCallback(() => {
    track('login step-1 google sign in clicked')
  }, [])
  const callback = useCallback(
    (credentialResponse: google.accounts.id.CredentialResponse) => {
      const credential = credentialResponse.credential
      const email = jwtDecode<{ email: string }>(credential).email
      onLogin(email, credential)
    },
    [onLogin]
  )

  return (
    <GoogleLoginButton
      client_id={window.CoderPad.GOOGLE_CLIENT_ID}
      className={styles.btnGoogle}
      onClick={handleClick}
      callback={callback}
      context="signin"
      locale="en"
      type="standard"
      width="400px"
      logo_alignment="center"
      size="large"
      shape="pill"
    />
  )
}
