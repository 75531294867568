import { gql, useQuery } from '@apollo/client'
import { Dialog, Link } from '@mui/material'
import { Alert } from '@mui/material'
import { EventEmitter } from 'events'
import React, { useEffect } from 'react'

import * as queryStates from '../../../../graphql/queryStates'

interface ISessionCheckerProps {
  pollInterval?: number
}

class AuthEventEmitter extends EventEmitter {
  refetchImmediately() {
    this.emit('refetch')
  }
}

// Export an emitter that can be used to force an immediate refetch of user data.
export const sessionEventEmitter = new AuthEventEmitter()

export const SESSION_USER_QUERY = gql`
  query SessionUser {
    user {
      id
      name
      email
      organizationOwner
    }
  }
`

/**
 * A component meant to wrap other components that require user information to be rendered. This component will
 * query for the current user's information. If the query fails, then a Dialog is shown informing the user that
 * they will need to login again. The user will not be able to close the Dialog, requiring them to either refresh
 * the page (which will redirect them to the login page) or click the link to take them back to the login page.
 *
 * The user query poll interval defaults to 5m. This should reduce the unusual cases where a user has logged out in
 * another tab, but also not overwhelm the GQL endpoint.
 */
export const SessionChecker: React.FC<ISessionCheckerProps> = ({
  children,
  pollInterval = 300000, // 5 minutes default
}) => {
  // NOTE: This intentionally does not use the `useCurrentUser` hook. On more than one occasion, a change was made to
  // the query in `useCurrentUser` that would cause the query to fail for field access violations, causing this
  // `SessionChecker` component to errantly think that the user's session expired. Using a query specific to just
  // this feature is the safest way to ensure that the query being used to check the session status will not fail
  // for reasons other than the user's session actually being expired.
  const { error, loading, refetch, startPolling, stopPolling } = useQuery(SESSION_USER_QUERY, {
    context: {
      source: 'useCurrentUser.ts',
    },
  })

  let status = queryStates.initial()
  if (loading) {
    status = queryStates.loading()
  } else if (error) {
    status = queryStates.error(
      `An error occurred while fetching your user information${
        error.message ? ': ' + error.message : ''
      }`
    )
  } else {
    status = queryStates.success()
  }

  useEffect(() => {
    if (queryStates.isErrorState(status)) {
      stopPolling()
    } else {
      startPolling(pollInterval)
    }
    return () => {
      stopPolling()
    }
  }, [status, pollInterval, startPolling, stopPolling])

  useEffect(() => {
    sessionEventEmitter.on('refetch', refetch)
  }, [])

  return (
    <>
      {children}
      <Dialog open={queryStates.isErrorState(status)}>
        <Alert severity="error">
          Your session has expired. Please refresh the page or{' '}
          <Link
            href="/login"
            sx={{ color: (theme) => theme.palette.tokens.text.link }}
            underline="hover"
          >
            login
          </Link>{' '}
          to continue.
        </Alert>
      </Dialog>
    </>
  )
}
