import { makeUseOrganizationHook } from '../useOrganization/useOrganization'

export const organizationFields = `
  name
  idpCertFingerprint
  idpEnabled
  idpIssuer
  idpSsoTargetUrl
  idpSloTargetUrl
  loginSubdomain
  samlSsoConfigured
  scimAuthToken
  singleSignOnUrl
  cognitoSsoConfigured
  numOfMembers
  ssoConfigurationAccessible
  cognitoSsoEnforced
  cognitoSsoSamlConfig {
    ssoEnforced
    plannedLegacySsoDeletionDate
    cognitoIdentityProviderName
  }
`

export const useOrganizationSamlSettings = makeUseOrganizationHook(organizationFields)
