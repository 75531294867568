import { useMemo } from 'react'

import { useWindow } from '../window'

export function isMobile() {
  const { userAgent } = window.navigator
  const ios = /AppleWebKit/.test(userAgent) && /Mobile\/\w+/.test(userAgent)
  return ios || /Android|webOS|BlackBerry|Opera Mini|Opera Mobi|IEMobile/i.test(userAgent)
}

export function isProduction() {
  return window.CoderPad.ENVIRONMENT === 'production'
}

export function isStaging() {
  return window.CoderPad.ENVIRONMENT === 'staging'
}

export function isDevelopment() {
  return window.CoderPad.ENVIRONMENT === 'development'
}

export function useEnvironment() {
  const window = useWindow()

  const isDevelopment = useMemo(() => window.CoderPad.ENVIRONMENT === 'development', [window])

  const isMac = useMemo(() => window.navigator.userAgent.includes('Mac'), [window])

  const isMobile = useMemo(() => {
    const { userAgent } = window.navigator
    const ios = /AppleWebKit/.test(userAgent) && /Mobile\/\w+/.test(userAgent)
    return ios || /Android|webOS|BlackBerry|Opera Mini|Opera Mobi|IEMobile/i.test(userAgent)
  }, [window])

  const isProduction = useMemo(() => window.CoderPad.ENVIRONMENT === 'production', [window])

  const isStaging = useMemo(() => window.CoderPad.ENVIRONMENT === 'staging', [window])

  return {
    isDevelopment,
    isMac,
    isMobile,
    isProduction,
    isStaging,
  }
}
