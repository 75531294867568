import { gql, useLazyQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import type { CognitoUser } from 'amazon-cognito-identity-js'
import { Amplify, Auth } from 'aws-amplify'
import { generate as generatePassword } from 'generate-password'
import { CognitoLoginMethod, CognitoUserPool, LoginMethod } from 'graphql/types'
import { track } from 'packs/main/coderpad_analytics'
import { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import * as queryStates from '../../../graphql/queryStates'
import { useSearchParams } from '../../../utils'
import {
  AmplifyCustomState,
  buildAmplifyConfig,
  setAmplifyConfig,
  storeAmplifyConfiguration,
  storeAmplifyCustomState,
} from '../../../utils/amplify/setAmplifyConfig'
import { useFetch } from '../../../utils/fetch/useFetch'
import { LoginError } from './LoginError'

interface ICustomParams {
  authenticity_token?: string
  'user[email]'?: string
  'user[password]'?: string
  commit?: string
}

export const GET_LOGIN_METHOD = gql`
  query GetLoginMethod($email: String!) {
    loginMethod(email: $email) {
      isCodingameUserOnly
      ssoLoginInfo {
        idpEnabled
        idpIssuer
        loginSubdomain
        cognitoSsoConfigured
        cognitoSsoSamlConfig {
          cognitoClientId
          cognitoIdentityProviderName
          ssoEnforced
        }
      }
      cognitoLoginInfo {
        authFlowType
        canUseCognitoLogin
        migratedOnCognito
        cognitoUsername
        cognitoUserPool {
          userPoolId
          userPoolWebClientId
          region
          name
        }
      }
      screenSsoLoginInfo {
        legacySsoEnabled
        legacySsoEnforced
      }
    }
  }
`

export const GET_SSO_CONFIG_BY_IDP_NAME = gql`
  query GetSsoConfigByIdpName($idpName: String!) {
    ssoConfigByIdpName(idpName: $idpName) {
      ssoLoginInfo {
        idpEnabled
        idpIssuer
        loginSubdomain
        cognitoSsoConfigured
        cognitoSsoSamlConfig {
          cognitoClientId
          cognitoIdentityProviderName
          ssoEnforced
        }
      }
      cognitoLoginInfo {
        cognitoUserPool {
          userPoolId
          userPoolWebClientId
          region
          name
        }
      }
    }
  }
`

function shouldUseCognito(cognitoLoginMethod: CognitoLoginMethod | undefined | null) {
  return (
    cognitoLoginMethod?.cognitoUserPool != null && (cognitoLoginMethod?.canUseCognitoLogin ?? false)
  )
}

const cognitoSsoLogin = async (
  { cognitoLoginInfo, ssoLoginInfo }: LoginMethod,
  email: string,
  hydraLoginChallenge: string | null
) => {
  const cognitoUsername = cognitoLoginInfo?.cognitoUsername
  const cognitoUserPool = cognitoLoginInfo?.cognitoUserPool

  const overridenUserpool: CognitoUserPool = {
    ...cognitoUserPool!,
    userPoolWebClientId: ssoLoginInfo!.cognitoSsoSamlConfig!.cognitoClientId!,
  }

  const amplifyConfiguration = buildAmplifyConfig(overridenUserpool)

  const nonce = uuid()
  const customState: AmplifyCustomState = {
    email,
    hydraLoginChallenge,
    migrated: cognitoUsername != null,
    nonce,
    redirectUrl: '/dashboard', // TODO where to find the redirectUrl we want ?
    loginMode: 'lever',
  }

  storeAmplifyConfiguration(amplifyConfiguration)
  storeAmplifyCustomState(customState)

  Amplify.configure(amplifyConfiguration)
  Auth.federatedSignIn({
    customProvider: ssoLoginInfo!.cognitoSsoSamlConfig!.cognitoIdentityProviderName!,
    customState: nonce,
  })
}

const screenSsoLogin = async (email: string) => {
  const redirectUrl = new URL('servlet/sso-login', window.CoderPad?.CODINGAME_LEGACY_SSO_BASE_URL)
  redirectUrl.searchParams.set('redirectUrl', window.location.origin)
  redirectUrl.searchParams.set('email', email)
  window.location.assign(redirectUrl.toString())
}

export const useLogin = () => {
  const [isCodingameUserOnly, setIsCodingameUserOnly] = useState(false)
  const [completedLoginMethodQuery, setCompletedLoginMethodQuery] = useState(false)
  const [_step, setStep] = useState<'EMAIL' | 'PASSWORD' | 'GOOGLE'>('EMAIL')
  const step = !completedLoginMethodQuery || isCodingameUserOnly ? 'EMAIL' : _step

  const [searchParams] = useSearchParams()
  const hydraLoginChallenge = searchParams.get('login_challenge')
  const idp = searchParams.get('idp')

  const [googleLoginDetails, setGoogleLoginDetails] = useState<{
    email: string
    credential: string
  }>()
  const [
    getLoginMethod,
    { data, loading: loadingQuery, error: errorQuery, variables },
  ] = useLazyQuery<
    {
      loginMethod: LoginMethod
    },
    { email: string }
  >(GET_LOGIN_METHOD, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const idpEnabled = data?.loginMethod.ssoLoginInfo?.idpEnabled
      const idpIssuer = data?.loginMethod.ssoLoginInfo?.idpIssuer

      const host = window.location.host
      const hostParts = host.split('.')
      const subdomain = hostParts[0]
      const isAdminOrLocalhost = subdomain === 'admin' || subdomain === 'localhost'

      if (
        data?.loginMethod?.ssoLoginInfo?.cognitoSsoConfigured &&
        variables != null &&
        data?.loginMethod?.ssoLoginInfo?.cognitoSsoSamlConfig?.ssoEnforced
      ) {
        cognitoSsoLogin(data!.loginMethod, variables.email, hydraLoginChallenge)
      } else if (isAdminOrLocalhost && idpEnabled && idpIssuer) {
        window.location.assign(
          `${window.location.protocol}//${host}/saml/init?issuer=${encodeURIComponent(idpIssuer)}`
        )
      } else if (idpEnabled && idpIssuer) {
        track('login step-1 use SSO enforced', () => {
          window.location.assign(
            `${window.location.protocol}//${host}/saml/init?issuer=${encodeURIComponent(idpIssuer)}`
          )
        })
      } else if (
        data?.loginMethod?.screenSsoLoginInfo?.legacySsoEnabled &&
        variables?.email != undefined
      ) {
        screenSsoLogin(variables.email!)
      } else {
        setCompletedLoginMethodQuery(true)
      }
    },
  })

  const [getSsoConfig, { data: ssoData }] = useLazyQuery<
    {
      ssoConfigByIdpName: LoginMethod
    },
    { idpName: string }
  >(GET_SSO_CONFIG_BY_IDP_NAME, {
    fetchPolicy: 'network-only',
    onError: () => {
      window.location.assign(window.location.pathname)
    },
  })

  const preflightEmail = useCallback(
    (email: string) => {
      setCompletedLoginMethodQuery(false)
      getLoginMethod({ variables: { email } })
    },
    [getLoginMethod]
  )

  const handleEmail = useCallback(
    (email: string) => {
      preflightEmail(email)
      setStep('PASSWORD')
    },
    [preflightEmail]
  )

  useEffect(() => {
    setIsCodingameUserOnly(data?.loginMethod.isCodingameUserOnly ?? false)
  }, [data])

  const {
    deviseSignIn,
    deviseGoogleSignIn,
    deviseLoginStatus,
    deviseUrlRedirect,
  } = useDeviseSignIn()
  const {
    cognitoSignIn,
    cognitoGoogleSignIn,
    cognitoLoginStatus,
    cognitoUrlRedirect,
  } = useCognitoSignIn(data?.loginMethod?.cognitoLoginInfo)

  const idpEnabled = data?.loginMethod.ssoLoginInfo?.idpEnabled ?? undefined
  const cognitoUsername = data?.loginMethod.cognitoLoginInfo?.cognitoUsername
  // check if one can attempt to login via cognito
  // mostly to make sure users part of a user-pool, but don't exist in cognito, are migrated
  const shouldUseCognitoLogin = shouldUseCognito(data?.loginMethod?.cognitoLoginInfo)

  const loginStatus = shouldUseCognitoLogin ? cognitoLoginStatus : deviseLoginStatus
  const urlRedirect = shouldUseCognitoLogin ? cognitoUrlRedirect : deviseUrlRedirect

  const login = useCallback(
    (email: string, password: string) => {
      if (shouldUseCognitoLogin) {
        if (data?.loginMethod.cognitoLoginInfo?.migratedOnCognito) {
          cognitoSignIn(cognitoUsername ?? email, password)
        } else {
          cognitoSignIn(email, password)
        }
      } else {
        deviseSignIn(email, password)
      }
    },
    [
      shouldUseCognitoLogin,
      data?.loginMethod.cognitoLoginInfo?.migratedOnCognito,
      cognitoSignIn,
      cognitoUsername,
      deviseSignIn,
    ]
  )

  const googleLogin = useCallback(
    async (email: string, credential: string) => {
      preflightEmail(email)
      // if sso enforced use cognito sso
      if (data?.loginMethod.ssoLoginInfo?.cognitoSsoSamlConfig?.ssoEnforced) {
        return cognitoSsoLogin(data!.loginMethod, email, hydraLoginChallenge)
      }
      setGoogleLoginDetails({
        email,
        credential,
      })
      setStep('GOOGLE')
    },
    [data, hydraLoginChallenge, preflightEmail]
  )

  useEffect(() => {
    if (step === 'GOOGLE' && googleLoginDetails != null) {
      const signIn = () => {
        if (shouldUseCognitoLogin) {
          if (data?.loginMethod.cognitoLoginInfo?.migratedOnCognito) {
            return cognitoGoogleSignIn(
              cognitoUsername ?? googleLoginDetails.email,
              googleLoginDetails.credential,
              data?.loginMethod?.cognitoLoginInfo
            )
          } else {
            return cognitoGoogleSignIn(
              googleLoginDetails.email,
              googleLoginDetails.credential,
              data?.loginMethod?.cognitoLoginInfo
            )
          }
        } else {
          return deviseGoogleSignIn(googleLoginDetails.email, googleLoginDetails.credential)
        }
      }
      signIn().catch((err) => {
        setStep('EMAIL')
        setGoogleLoginDetails(undefined)
      })
    }
  }, [
    cognitoGoogleSignIn,
    cognitoUsername,
    completedLoginMethodQuery,
    data?.loginMethod?.cognitoLoginInfo,
    deviseGoogleSignIn,
    googleLoginDetails,
    shouldUseCognitoLogin,
    step,
  ])

  useEffect(() => {
    if (idp != null) {
      if (ssoData == null) {
        getSsoConfig({ variables: { idpName: idp } })
      }
      if (ssoData?.ssoConfigByIdpName?.ssoLoginInfo?.cognitoSsoConfigured) {
        cognitoSsoLogin(ssoData.ssoConfigByIdpName, '', hydraLoginChallenge)
      }
    }
  }, [ssoData, idp, hydraLoginChallenge, getSsoConfig])

  return {
    handleEmail,
    login,
    googleLogin,
    loginStatus,
    urlRedirect,
    setStep,
    step,
    loadingQuery,
    errorQuery,
    idpEnabled,
    isCodingameUserOnly,
  }
}

async function migrateSocialUser(
  cognitoUsername: string,
  customAuthCredentials: string
): Promise<CognitoUser | undefined> {
  try {
    return await Auth.signIn({
      username: cognitoUsername,
      password: generatePassword({
        length: 20,
        numbers: true,
        symbols: true,
        strict: true,
      }),
      validationData: {
        customAuthCredentials,
      },
    })
  } catch (error) {
    console.error(
      'Unable to migrate user on cognito, they are probably already migrated, falling back to original method'
    )
    // Unable to migrate
    return undefined
  }
}

export const useFetchNewLoginSession = () => {
  const fetch = useFetch()

  return useCallback(
    async (
      jwtToken: string,
      hydraLoginChallenge?: string | null,
      googleLogin: boolean = false,
      ssoLogin: boolean = false
    ) => {
      const params = setParams({})

      const searchParams = new URLSearchParams(window.location.search)
      if (hydraLoginChallenge != null) {
        searchParams.set('login_challenge', hydraLoginChallenge)
      }

      if (googleLogin != null) {
        searchParams.set('google_login', googleLogin.toString())
      }

      if (ssoLogin != null) {
        searchParams.set('sso_login', ssoLogin.toString())
      }

      const res = await fetch(`/newlogin_session?${searchParams.toString()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${jwtToken}`,
        },
        body: params.toString(),
      })

      if (!res.ok) {
        throw new Error(`Could not create a session. Login failed with ${res.status}`)
      }

      const resJson: {
        userSignedIn: boolean
        urlRedirect?: string
        migratedToCognito?: boolean
        cognitoEmail?: string
      } = await res.json()

      return resJson
    },
    [fetch]
  )
}

export const useFetchReportCognitoLoginError = () => {
  const fetch = useFetch()

  return useCallback(
    async (cognitoUsername: string, message: string, googleLogin: boolean = false) => {
      const params = new URLSearchParams([
        ['cognito_username', cognitoUsername],
        ['message', message],
      ])

      const searchParams = new URLSearchParams(window.location.search)
      if (googleLogin != null) {
        searchParams.set('google_login', googleLogin.toString())
      }

      const res = await fetch(`/report_cognito_error?${searchParams.toString()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params.toString(),
      })

      if (!res.ok) {
        throw new Error(`Could not report login error. Failed with ${res.status}`)
      }
    },
    [fetch]
  )
}

async function connectSocialUser(cognitoUsername: string, customAuthCredentials: string) {
  // Sign in in cognito user CUSTOM_AUTH (automatic when no password is provided)
  let cognitoUser: CognitoUser = await Auth.signIn(cognitoUsername)
  // Expect cognito to ask from a custom challenge
  if (cognitoUser.challengeName !== 'CUSTOM_CHALLENGE') {
    throw new Error(`Unexpected challenge ${cognitoUser.challengeName}`)
  }
  // Answer the challenge with the google jwt token
  cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, customAuthCredentials)

  return cognitoUser
}

const useCognitoSignIn = (userLogin: LoginMethod['cognitoLoginInfo']) => {
  const fetchNewLoginSession = useFetchNewLoginSession()
  const reportCognitoError = useFetchReportCognitoLoginError()
  const [loginStatus, setLoginStatus] = useState<queryStates.QueryState>(queryStates.initial())
  const [url, setUrl] = useState('/')

  const handleCognitoError = useCallback(
    (cognitoUsername: string, viaPassword: boolean, err: Error) => {
      if (err.name !== 'NotAuthorizedException' || !viaPassword) {
        void reportCognitoError(cognitoUsername, 'Unknown error', !viaPassword)
        setLoginStatus(queryStates.error(LoginError.UNKNOWN_ERROR, err))
        Sentry.captureMessage(`cognitoSignIn: ${err}`, {
          level: 'error' as Sentry.SeverityLevel,
          tags: { layer: 'useLogin', feature: 'Login' },
        })
      } else {
        void reportCognitoError(cognitoUsername, 'Incorrect username or password', !viaPassword)
        setLoginStatus(queryStates.error('Incorrect username or password. Please try again.', err))
      }
    },
    [reportCognitoError]
  )

  const signInWithCognitoJwt = useCallback(
    async (jwtToken: string, googleLogin: boolean = false) => {
      const resJson = await fetchNewLoginSession(jwtToken, null, googleLogin)

      if (resJson.userSignedIn) {
        setUrl(resJson.urlRedirect!)
        setLoginStatus(queryStates.success())
      } else if (resJson.urlRedirect != null) {
        // userSignedIn = false AND urlRedirect != null means it's an Hydra redirect url
        window.location.assign(resJson.urlRedirect)
        return
      } else {
        throw new Error('Could not create a session. User is not signed in.')
      }
    },
    [fetchNewLoginSession]
  )

  const cognitoSignIn = useCallback(
    async (cognitoUsername: string, password: string) => {
      setLoginStatus(queryStates.loading())
      setAmplifyConfig(userLogin?.cognitoUserPool, userLogin?.authFlowType)

      try {
        const cognitoUser: CognitoUser = await Auth.signIn(cognitoUsername, password)
        const jwtToken = cognitoUser.getSignInUserSession()!.getIdToken().getJwtToken()

        if (!jwtToken) {
          throw new Error('Cognito user is missing a jwtToken')
        }

        await signInWithCognitoJwt(jwtToken)
      } catch (err: unknown) {
        if (!(err instanceof Error)) {
          throw new Error('Unknown error')
        }
        handleCognitoError(cognitoUsername, true, err)
      }
    },
    [userLogin?.cognitoUserPool, userLogin?.authFlowType, signInWithCognitoJwt, handleCognitoError]
  )

  const cognitoGoogleSignIn = useCallback(
    async (
      cognitoUsername: string,
      credential: string,
      _userLogin: CognitoLoginMethod | undefined | null = userLogin
    ) => {
      setLoginStatus(queryStates.loading())
      setAmplifyConfig(_userLogin?.cognitoUserPool, _userLogin?.authFlowType)

      const customAuthCredentials = JSON.stringify({
        provider: 'google',
        answer: credential,
      })

      try {
        let cognitoUser: CognitoUser | undefined
        if (_userLogin?.cognitoUsername == null || !_userLogin?.migratedOnCognito) {
          cognitoUser = await migrateSocialUser(cognitoUsername, customAuthCredentials)
        }
        if (cognitoUser == null) {
          cognitoUser = await connectSocialUser(cognitoUsername, customAuthCredentials)
        }

        const jwtToken = cognitoUser.getSignInUserSession()!.getIdToken().getJwtToken()

        if (jwtToken == null) {
          throw new Error('Cognito user is missing a jwtToken')
        }

        await signInWithCognitoJwt(jwtToken, true)
      } catch (err: unknown) {
        if (!(err instanceof Error)) {
          throw new Error('Unknown error')
        }
        handleCognitoError(cognitoUsername, false, err)
        throw err
      }
    },
    [handleCognitoError, signInWithCognitoJwt, userLogin]
  )

  return {
    cognitoSignIn,
    cognitoGoogleSignIn,
    cognitoLoginStatus: loginStatus,
    cognitoUrlRedirect: url,
  }
}

const useDeviseSignIn = () => {
  const fetch = useFetch()
  const [loginStatus, setLoginStatus] = useState<queryStates.QueryState>(queryStates.initial())
  const [url, setUrl] = useState('/')

  const deviseSignIn = useCallback(
    async (email: string, password: string) => {
      setLoginStatus(queryStates.loading())
      const params = setParams({
        authenticity_token: fetch.authToken,
        'user[email]': email,
        'user[password]': password,
        commit: 'Log in',
      })

      try {
        const res = await fetch('/login.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: params.toString(),
        })

        const contentType = res.headers.get('content-type')
        if (contentType == null || !contentType.includes('application/json')) {
          throw new Error(`Could not create a session. Login failed with invalid Content-Type`)
        }

        const resJson: {
          error?: string
          urlRedirect?: string
        } = await res.json()

        if (!res.ok) {
          setLoginStatus(queryStates.error(resJson.error ?? LoginError.UNKNOWN_ERROR))
        } else {
          setUrl(resJson.urlRedirect!)
          setLoginStatus(queryStates.success())
        }
      } catch (err: unknown) {
        setLoginStatus(queryStates.error(LoginError.UNKNOWN_ERROR, err as Error))

        Sentry.captureMessage(`deviseSignIn: ${err}`, {
          level: 'error' as Sentry.SeverityLevel,
          tags: { layer: 'useLogin', feature: 'Login' },
        })
      }
    },
    [fetch, setLoginStatus]
  )

  const deviseGoogleSignIn = useCallback(
    async (email: string, credential: string) => {
      setLoginStatus(queryStates.loading())

      const params = setParams({})

      try {
        const res = await fetch(`/google_login${window.location.search}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${credential}`,
          },
          body: params.toString(),
        })

        const contentType = res.headers.get('content-type')
        if (contentType == null || !contentType.includes('application/json')) {
          throw new Error(`Could not create a session. Login failed with invalid Content-Type`)
        }

        const resJson: {
          userSignedIn: boolean
          urlRedirect?: string
        } = await res.json()

        if (resJson.userSignedIn) {
          setUrl(resJson.urlRedirect!)
          setLoginStatus(queryStates.success())
        } else if (resJson.urlRedirect != null) {
          window.location.assign(resJson.urlRedirect)
          return
        } else {
          throw new Error('Could not create a session. User is not signed in.')
        }
      } catch (err: unknown) {
        // if it fails, revoke the account access so it's not proposed by default next time
        window.google.accounts.id.revoke(email)

        if (!(err instanceof Error)) {
          throw new Error('Unknown error')
        }

        setLoginStatus(queryStates.error(LoginError.UNKNOWN_ERROR, err))
        if (err.name !== 'NotAuthorizedException') {
          Sentry.captureMessage(`cognitoSignIn: ${err}`, {
            level: 'error' as Sentry.SeverityLevel,
            tags: { layer: 'useLogin', feature: 'Login' },
          })
        }
        throw err
      }
    },
    [fetch, setLoginStatus]
  )

  return {
    deviseSignIn,
    deviseGoogleSignIn,
    deviseLoginStatus: loginStatus,
    deviseUrlRedirect: url,
  }
}

const setParams = (customParams: ICustomParams) => {
  const searchWindowParams = new URLSearchParams(window.location.search)

  const params = new URLSearchParams(
    Object.entries(customParams).filter(([key, value]) => value != null)
  )

  const buyNow = searchWindowParams.get('buy_now')
  if (buyNow != null) params.set('buy_now', buyNow)

  const planIntent = searchWindowParams.get('plan_intent')
  if (planIntent != null) params.set('plan_intent', planIntent)

  return params
}
