import { datadogRum } from '@datadog/browser-rum'

export function initDatadogRum() {
  datadogRum.init({
    applicationId: 'a0cedc14-6165-456a-8e54-8db41faec449',
    beforeSend(event) {
      event.context = {
        ...event.context,
        product: 'live',
      }
    },
    clientToken: window.CoderPad.DATADOG_RUM_CLIENT_TOKEN || '',
    defaultPrivacyLevel: 'mask-user-input',
    env: window.CoderPad.DATADOG_RUM_ENV,
    sampleRate: 100,
    service: 'app.coderpad.io',
    sessionReplaySampleRate: 1,
    site: 'datadoghq.com',
    trackInteractions: true,
    trackLongTasks: true,
    trackResources: true,
  })

  datadogRum.startSessionReplayRecording()
}
