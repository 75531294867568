/**
 * Define the types available for assorted GraphQL queries/mutations. State for a given query/mutation is defined by
 * the following things:
 *
 * A "kind". This is an enum that defines the four states a query can be in. Loading, success, error, or initial.
 *
 * A "message". This is some accompanying string that describes the state of the query.
 *
 * For "error" query states, it is up to the query/mutation to define what it considers an "error". An error can
 * be derived from a GraphQL error (malformed query, bad args, network error...) or from data inside the query
 * response (form field errors, insufficient privileges, etc...)
 *
 * The `initial`, `error`, `success`, and `loading` helper functions are defined below as a convenience for devs.
 * These are similar to the idea of action creators in Redux, where you provide some context for the action, and the
 * action creator returns a well-formed action. The helper functions below take a message about the query state and
 * return a well-formed query state tuple.
 */

// The QueryState is a tuple of a kind enum, a message, and possibly an object with extra info.
export type QueryState = [kind, string, { [key: string]: any }?]

// Queries/mutations can be in one of four states.
export enum kind {
  Initial = 'INITIAL', // The query has not run yet.
  Loading = 'LOADING', // The query is currently running.
  Success = 'SUCCESS', // The query has run, and run successfully.
  Error = 'ERROR', // The query has run, and an error occurred.
}

// Helper function to get an `initial` state.
export function initial(): QueryState {
  return [kind.Initial, '']
}

// Helper function to get an `error` state.
export function error(message: string = '', originalError?: Error): QueryState {
  return [kind.Error, message, { originalError }]
}

// Helper function to get a `success` state.
export function success(message: string = ''): QueryState {
  return [kind.Success, message]
}

// Helper function to get a `loading` state.
export function loading(message: string = ''): QueryState {
  return [kind.Loading, message]
}

export function isLoadingState(state: QueryState) {
  return state && state[0] === kind.Loading
}

export function isErrorState(state: QueryState) {
  return state && state[0] === kind.Error
}

export function isSuccessState(state: QueryState) {
  return state && state[0] === kind.Success
}

export function isInitialState(state: QueryState) {
  return state && state[0] === kind.Initial
}
