import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '16vh',
    '& > h6': {
      fontFamily: `Roboto`,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1.625rem',
      lineHeight: '30px',
      color: '#353C4E',
      margin: '0px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '30px',
      },
    },
    '& > span': {
      fontFamily: 'Roboto, Roboto Mono, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '20px',
      color: '#4F5053',
      marginTop: '4px',
    },
    '& > p': {
      fontFamily: 'Roboto, Roboto Mono, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.84375rem',
      lineHeight: '16px',
      color: '#515050',
      marginTop: '38px',
      '& > a': {
        color: '#515050',
        fontWeight: 400,
        textDecoration: 'underline',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: '100%',
      marginTop: '0px',
      marginLeft: '16px',
      marginRight: '16px',
    },
  },
  dividers: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '33px',
    marginBottom: '26px',
    '& > span': {
      color: '#8b8a8a',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '20px',
      textAlign: 'center',
      margin: '0px 6px !important',
    },
  },
  divider: {
    height: '1px',
    backgroundColor: '#DBDDE5',
    width: '180px',
    [theme.breakpoints.down('sm')]: {
      width: '0%',
      maxWidth: '230px',
      minWidth: '35vw',
    },
  },
}))
