import { Alert, Box, Collapse, Divider, Typography } from '@mui/material'
import { LoadingBlock } from 'packs/dashboard/components/LoadingBlock/LoadingBlock'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import * as queryStates from '../../../graphql/queryStates'
import { useSearchParams } from '../../../utils'
import { track } from '../../main/coderpad_analytics'
import { GoogleSignInBtn } from '../components/GoogleSignIn'
import { SignUp } from '../components/SignUp/Signup'
import { EmailForm } from '../EmailForm/EmailForm'
import { PasswordForm } from '../PasswordForm/PasswordForm'
import { COGNITO_SSO_ERROR_KEY, SsoSigninErrorType } from '../PostSsoLoginPage/PostSsoLoginPage'
import { EventErrorLabel, getErrorLabel } from '../useLogin/LoginError'
import { useLogin } from '../useLogin/useLogin'
import { useStyles } from './LoginForm.style'

const defaultErrorMessage = 'Internal server error, please try to log-in again'

const cognitoSsoErrorMessage: Record<SsoSigninErrorType, string> = {
  [SsoSigninErrorType.CANNOT_VALIDATE_CONFIG]: defaultErrorMessage,
  [SsoSigninErrorType.CANNOT_LOGIN]:
    'Unable to log-in with the provided email, did you log-in as the correct user with your Identity Provider ?',
  [SsoSigninErrorType.UNKNOWN_EMAIL]: 'Unknown Email',
  [SsoSigninErrorType.MULTIPLE_USERS_WITH_EMAIL]: defaultErrorMessage,
  [SsoSigninErrorType.IDP_LINK_SUCCESSFUL]:
    'Your account is now linked to your Identity Provider, you can try to log-in again',
  [SsoSigninErrorType.UNKNOWN]: 'An error occured, please try again',
}

export const secondaryLinkTrack = (step: number, link: string) => {
  track(`login step-${step} secondary link clicked`, { link })
}

export const errorViewedTrack = (step: number, error: EventErrorLabel) => {
  track(`login step-${step} error message viewed`, { error })
}

const appNotice = window.CP_APP_NOTICE

export const LoginForm: React.FC = () => {
  const styles = useStyles()
  const {
    handleEmail,
    login,
    googleLogin,
    loginStatus,
    urlRedirect,
    step,
    setStep,
    loadingQuery,
    errorQuery,
    idpEnabled,
    isCodingameUserOnly,
  } = useLogin()

  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string>()
  const [fieldError, setFieldError] = useState(false)
  const [searchParams] = useSearchParams()
  const idp = searchParams.get('idp')
  const [defaultEmail, setDefaultEmail] = useState(searchParams.get('default_email'))

  const resetError = useCallback(() => {
    setError(undefined)
  }, [setError])

  const handleEmailChange = useCallback(
    (targetEmail: string) => {
      resetError()
      setEmail(targetEmail)
    },
    [resetError, setEmail]
  )

  const handleLogin = useCallback(async () => {
    if (password.length === 0) {
      return
    }

    login(email, password)
    track('login step-2 password submitted')
  }, [email, login, password])

  const handleContinue = useCallback(() => {
    if (email.length === 0) {
      return
    }

    handleEmail(email)
  }, [email, handleEmail])

  const handleSignUpClick = () => {
    secondaryLinkTrack(step === 'PASSWORD' ? 2 : 1, 'sign up')
  }

  useEffect(() => {
    if (defaultEmail == null || defaultEmail == '') {
      return
    }
    handleEmailChange(defaultEmail)
    handleEmail(defaultEmail)
    setDefaultEmail(null)
  }, [defaultEmail, handleContinue, handleEmail, handleEmailChange, history, setStep])

  useEffect(() => {
    if (errorQuery) {
      setError('Something went wrong. Please try again.')
    }
  }, [errorQuery])

  useEffect(() => {
    if (queryStates.isSuccessState(loginStatus)) {
      window.location.href = urlRedirect
    }
  }, [loginStatus, urlRedirect])

  useEffect(() => {
    if (loginStatus[1] != null && loginStatus[1].length > 0) {
      setPassword('')
      setError(loginStatus[1])
      errorViewedTrack(2, getErrorLabel(loginStatus[1]))
    }
  }, [loginStatus])

  useEffect(() => {
    if (step === 'PASSWORD') {
      resetError()
    }
  }, [resetError, step])

  useEffect(() => {
    if (fieldError) {
      if (step === 'PASSWORD') {
        errorViewedTrack(2, EventErrorLabel.INVALID_PASSWORD)
      } else {
        errorViewedTrack(1, EventErrorLabel.INVALID_EMAIL)
      }
    }
  }, [fieldError, step])

  useEffect(() => {
    window.onpageshow = (event) => {
      if (event.persisted) {
        window.location.reload()
      }
    }
  }, [])

  useEffect(() => {
    const cognitoSsoError = localStorage.getItem(COGNITO_SSO_ERROR_KEY)
    if (cognitoSsoError != null) {
      localStorage.removeItem(COGNITO_SSO_ERROR_KEY)
      const { errorDescription, attemptedEmail } = JSON.parse(cognitoSsoError)
      let errorMessage = cognitoSsoErrorMessage[errorDescription]

      if (errorMessage == null) {
        // errorDescription is not a known error
        errorMessage = cognitoSsoErrorMessage[SsoSigninErrorType.UNKNOWN]
      }

      setError(errorMessage)
      if (attemptedEmail != null) {
        setEmail(attemptedEmail)
      }
    }
  }, [])

  if (idp != null) {
    return <LoadingBlock isLoading />
  }

  return (
    <>
      <SignUp onClick={handleSignUpClick} />

      <Box className={styles.form}>
        <Typography variant="h6" component="h6">
          Log In
        </Typography>

        <Collapse in={appNotice !== ''}>
          <Alert severity="info" sx={{ marginTop: '23px' }}>
            {appNotice}
          </Alert>
        </Collapse>

        <Collapse in={error != null}>
          <Alert severity="error" sx={{ marginTop: '23px' }}>
            {error}
          </Alert>
        </Collapse>

        <Collapse in={step === 'EMAIL' || step === 'GOOGLE'} unmountOnExit={true}>
          <GoogleSignInBtn onLogin={googleLogin} />

          <Box className={styles.dividers}>
            <Divider classes={{ root: styles.divider }} />
            <Typography variant="caption" component="span">
              or
            </Typography>
            <Divider classes={{ root: styles.divider }} />
          </Box>

          <EmailForm
            disabled={step !== 'EMAIL'}
            email={email}
            handleEmailChange={handleEmailChange}
            handleContinue={handleContinue}
            onErrorChange={setFieldError}
            loadingQuery={loadingQuery}
            idpEnabled={idpEnabled}
            isCodingameUserOnly={isCodingameUserOnly}
          />
        </Collapse>

        <Collapse in={step === 'PASSWORD'} unmountOnExit={true}>
          <PasswordForm
            email={email}
            password={password}
            setPassword={setPassword}
            isLoading={queryStates.isLoadingState(loginStatus)}
            setShowPasswordForm={() => setStep('EMAIL')}
            handleLogin={handleLogin}
            onChangePassword={resetError}
            onErrorChange={setFieldError}
            resetError={resetError}
          />
        </Collapse>
      </Box>
    </>
  )
}
