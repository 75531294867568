import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { AuthenticationSidebar } from '../authentication/components/AuthenticationSidebar/AuthenticationSidebar'
import { ErrorBoundary } from '../dashboard/components/ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../dashboard/components/GenericErrorView/GenericErrorView'
import { LoginForm } from './LoginForm/LoginForm'
import EmailForm from './PasswordReset/EmailForm'
import EmailSent from './PasswordReset/EmailSent'
import NewPasswordForm from './PasswordReset/NewPasswordForm'

export const Login: React.FC = () => {
  return (
    <ErrorBoundary fallback={(e) => <GenericErrorView error={e} />}>
      <Router>
        <AuthenticationSidebar>
          <Switch>
            <Route path="/login" component={LoginForm} />
            <Route path="/password/new" component={EmailForm} />
            <Route path="/password/edit" component={NewPasswordForm} />
            <Route path="/email_sent" component={EmailSent} />
          </Switch>
        </AuthenticationSidebar>
      </Router>
    </ErrorBoundary>
  )
}
