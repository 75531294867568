import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFetch } from 'utils/fetch/useFetch'

import { AuthenticationButton } from '../../authentication/components/AuthenticationButton/AuthenticationButton'
import { SignUp } from '../components/SignUp/Signup'

const EmailForm: React.FC = () => {
  const [email, setEmail] = useState('')
  const form = useRef(null)
  const fetcher = useFetch()
  const history = useHistory()

  const disabled = email.trim().length === 0

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!form.current) {
      return
    }

    const data = new FormData(form.current)
    const res = await fetcher('/password.json', { method: 'POST', body: data })

    if (res.ok) {
      history.push('/email_sent')
    }
  }

  return (
    <>
      <SignUp />
      <Box sx={{ width: '400px', margin: '0 auto', marginTop: '16vh' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '50px' }}>
          <IconButton type="text" href="/login" sx={{ color: '#000000' }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h2">Forgotten password?</Typography>
        </Box>
        <form ref={form} id="new_user" onSubmit={onSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              placeholder="Work email"
              size="small"
              id="user_email"
              name="user[email]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <AuthenticationButton type="submit" sx={{ marginTop: '24px' }} disabled={disabled}>
              Reset password
            </AuthenticationButton>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default EmailForm
