import React from 'react'

export const fetcherContext = React.createContext(
  (url: string, opts: Record<string, unknown>): Promise<Response> => Promise.resolve(new Response())
)

/**
 * Provider to expose a fetch function to its descendants.
 */
export const FetchProvider: React.FC<{ fetcher: any }> = ({ fetcher, children }) => {
  return <fetcherContext.Provider value={fetcher}>{children}</fetcherContext.Provider>
}
