import React, { useEffect, useRef, useState } from 'react'

export interface GoogleLoginButtonProps
  extends Pick<google.accounts.id.IdConfiguration, 'client_id' | 'callback' | 'context'>,
    google.accounts.id.GsiButtonConfiguration {
  className?: string
  onClick?: () => void
}

interface FixedGsiButtonConfiguration extends google.accounts.id.GsiButtonConfiguration {
  /** this param is not defined in the types but is present in the documentation
   * see https://developers.google.com/identity/gsi/web/reference/js-reference#click_listener
   */
  click_listener?: () => void
}

function useLoadGsiScript(): boolean {
  const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] = useState(false)

  useEffect(() => {
    const scriptTag = document.createElement('script')
    scriptTag.src = 'https://accounts.google.com/gsi/client'
    scriptTag.async = true
    scriptTag.defer = true
    scriptTag.onload = () => {
      setScriptLoadedSuccessfully(true)
    }
    scriptTag.onerror = () => {
      setScriptLoadedSuccessfully(false)
    }

    document.body.appendChild(scriptTag)

    return () => {
      document.body.removeChild(scriptTag)
    }
  }, [])

  return scriptLoadedSuccessfully
}

export default function GoogleLoginButton({
  client_id,
  context,
  className,
  callback,
  type = 'standard',
  theme = 'outline',
  size = 'large',
  text,
  shape,
  logo_alignment,
  width,
  locale,
  onClick,
}: GoogleLoginButtonProps) {
  const btnContainerRef = useRef<HTMLDivElement>(null)

  const ready = useLoadGsiScript()

  useEffect(() => {
    if (!ready || window.google == null) {
      return
    }
    window.google!.accounts.id.initialize({
      client_id,
      callback,
      context,
    })

    const buttonOptions: FixedGsiButtonConfiguration = {
      type,
      theme,
      size,
      text,
      shape,
      logo_alignment,
      width,
      locale,
      click_listener: onClick,
    }

    window.google!.accounts.id.renderButton(btnContainerRef.current!, buttonOptions)
  }, [
    ready,
    type,
    theme,
    size,
    text,
    shape,
    logo_alignment,
    width,
    locale,
    callback,
    onClick,
    client_id,
    context,
  ])

  return <div ref={btnContainerRef} className={className} />
}
