import { useCallback, useEffect, useState } from 'react'

import { useOrganizationSamlSettings } from '../graphql/hooks/organization/useOrganizationSamlSettings/useOrganizationSamlSettings'
import { useFetch } from './fetch/useFetch'

export const cpDomain =
  (window.CoderPad?.ENVIRONMENT ?? '') === 'production'
    ? 'https://coderpad.io'
    : 'https://coderpad-staging.io'

export const cgBaseUrl = window.CoderPad?.CODINGAME_BASE_URL

export interface CodingameCognitoSsoConfig {
  xmlConfigFile?: string
  metadataUrl: string
  identityProviderName?: string
  cognitoClientId?: string
  numberOfPendingLinks: number
  numberOfErrorLinks: number
  scimUrl: string
  scimAuthToken: string
  spEntityId: string
  spAssertionConsumerUrl: string
  idpLoginUrl: string
}

export const useCodingameCognitoSsoConfig = () => {
  const [config, setConfig] = useState<CodingameCognitoSsoConfig>()
  const [pending, setPending] = useState(true)

  const refecthConfig = useCallback(async () => {
    setPending(true)
    setConfig(undefined)
    try {
      const response = await fetch('/cognito_sso_configuration')
      setConfig(await response.json())
    } catch (e) {
      setConfig(undefined)
    } finally {
      setPending(false)
    }
  }, [])

  useEffect(() => {
    refecthConfig()
  }, [refecthConfig])

  return [config, pending, refecthConfig] as const
}

export const useUpdateCodingameCognitoSsoConfig = (refetchConfig: () => void) => {
  const fetcher = useFetch()
  const { refetch: refecthOrganizationSamlSettings } = useOrganizationSamlSettings()

  return async (
    xmlConfigFile: string,
    ssoEnforced: boolean,
    loginSubdomain?: string
  ): Promise<CodingameCognitoSsoConfig> => {
    const response = await fetcher('/cognito_sso_configuration', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ xmlConfigFile, ssoEnforced, loginSubdomain }),
    })

    refecthOrganizationSamlSettings()
    refetchConfig()

    return response.json()
  }
}
