export enum LoginError {
  // Error messages sent by Cognito, please do not change
  INVALID_USERNAME_OR_PASSWORD = 'Incorrect username or password.',
  ACCOUNT_DISABLED = 'User is disabled.',
  PASSWORD_ATTEMPTS_EXCEEDED = 'Password attempts exceeded',

  // Custom error messages
  UNKNOWN_ERROR = 'Something went wrong. Please try again.',
}

export enum EventErrorLabel {
  // Step 1 (email)
  INVALID_EMAIL = 'invalidEmail',

  // Step 2 (password)
  INVALID_PASSWORD = 'invalidPassword',
  INVALID_USERNAME_OR_PASSWORD = 'invalidLoginOrPassword',
  ACCOUNT_DISABLED = 'accountLocked',
  PASSWORD_ATTEMPTS_EXCEEDED = 'passwordAttemptsExceeded',
  UNKNOWN_ERROR = 'unknownError',
}

export const getErrorLabel = (loginError: string): EventErrorLabel => {
  switch (loginError) {
    case LoginError.INVALID_USERNAME_OR_PASSWORD:
      return EventErrorLabel.INVALID_USERNAME_OR_PASSWORD
    case LoginError.ACCOUNT_DISABLED:
      return EventErrorLabel.ACCOUNT_DISABLED
    case LoginError.PASSWORD_ATTEMPTS_EXCEEDED:
      return EventErrorLabel.PASSWORD_ATTEMPTS_EXCEEDED
    default:
      return EventErrorLabel.UNKNOWN_ERROR
  }
}
